import { useForm } from "@xtreamsrl/react-forms";
import { useCallback, useMemo, useState } from "react";
import * as yup from "yup";
import { useCreateCommunicationMutation } from "../mutations/useCreateCommMutation.ts";
import { useRecreateCommMutation } from "../mutations/useRecreateCommMutation.ts";
import { useContentCreationSlice } from "../slice/contentCreationSlice.ts";
import {
  CommunicationDetails,
  Communication,
  CommunicationType,
  BriefDemForm,
} from "../types";
import { useBriefData } from "./useBriefData.ts";
import { useCommunicationSetter } from "./useCommunicationSetter.ts";
import { useCommunicationType } from "./useCommunicationType.ts";
import { usePersonaListQuery } from "../queries/usePersonaListQuery.ts";
import { DemTemplateComponent } from "src/templates/types/index.ts";
import { pages } from "@reaidy/pages";
import { useBrowserNavigation } from "@xtreamsrl/react-routing";

const MAX_FILES = 3;
export const ALLOWED_MIME_TYPES = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "image/webp",
  "image/svg+xml",
  "application/pdf",
];

const validationSchema = yup.object().shape({
  type: yup
    .string()
    .oneOf([
      CommunicationType.DEM,
      CommunicationType.RCS,
      CommunicationType.PAPER_MAIL,
    ])
    .required(),
  source: yup.string().oneOf(["MANUAL", "IMPORTED"]).required(),
  layoutStructure: yup.mixed().required(),
  brief: yup.array().of(
    yup.object().shape({
      question: yup.string().required(),
      answer: yup.string().required(),
    }),
  ),
  files: yup
    .mixed<FileList>()
    .test("fileType", "Only images and PDFs are allowed", (value) => {
      if (!value || value.length === 0) return true;

      return Array.from(value).every((file) =>
        ALLOWED_MIME_TYPES.includes(file.type),
      );
    })
    .test("maxFiles", "Maximum 3 files allowed", (value) => {
      if (!value || value.length === 0) return true;
      if (value.length > MAX_FILES) return false;
      return true;
    }),
});

// function isBriefDemForm(object: unknown): object is CommunicationDetails {
//   return validationSchema.isValidSync(object, { abortEarly: false });
// }

export function useCreateCommunication({
  brief,
  onSuccess,
  onError,
  onStreamChunk,
  onStreamError,
  onFirstStreamChunk,
}: {
  brief: CommunicationDetails["brief"];
  onSuccess?: (communication: Communication) => void;
  onError?: (error: unknown) => void;
  onStreamChunk?: (chunk: string) => void;
  onStreamError?: (error: unknown) => void;
  onFirstStreamChunk?: (chunk: string) => void;
}) {
  const communicationType = useCommunicationType();
  if (!communicationType) throw new Error("Communication type is required");

  const [initialBriefData] = useBriefData();
  if (!initialBriefData) throw new Error("Initial brief data is required");

  const communication = useContentCreationSlice((state) => state.communication);
  const setCommunication = useCommunicationSetter();
  const { goTo } = useBrowserNavigation();

  const [firstChunkReceived, setFirstChunkReceived] = useState(false);
  const handleStreamChunk = useCallback(
    (chunk: string) => {
      onStreamChunk?.(chunk);
      if (!firstChunkReceived) {
        setFirstChunkReceived(true);
        onFirstStreamChunk?.(chunk);
      }
    },
    [onStreamChunk, onFirstStreamChunk, firstChunkReceived],
  );

  const handleStreamEnd = useCallback(() => {
    if (!communication?.id) {
      throw new Error("Communication ID is required");
    }

    goTo(pages.editById(communication.id));

    if (firstChunkReceived) {
      setFirstChunkReceived(false);
    }
  }, [firstChunkReceived, setFirstChunkReceived, goTo, communication?.id]);

  const {
    mutate: create,
    isPending: isCreating,
    isSuccess: hasCreated,
  } = useCreateCommunicationMutation({
    onStreamChunk: handleStreamChunk,
    onStreamError: onStreamError,
    onStreamEnd: handleStreamEnd,
    onError,
  });

  const {
    mutate: regenerate,
    isPending: isRegenerating,
    isSuccess: hasRegenerated,
  } = useRecreateCommMutation({
    onStreamError,
    onStreamChunk: handleStreamChunk,
    onStreamEnd: handleStreamEnd,
    onError,
  });

  const isPending = communication ? isRegenerating : isCreating;
  const isSuccess = communication ? hasRegenerated : hasCreated;

  const initialValues = useMemo<BriefDemForm & { files: FileList }>(
    () => ({
      type: initialBriefData.type as CommunicationType.DEM,
      layoutStructure: initialBriefData.layoutStructure as DemTemplateComponent,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      layoutHtml: (initialBriefData as any).layoutHtml,
      source: initialBriefData.source as "MANUAL",
      brief,
      files: new DataTransfer().files,
    }),
    [initialBriefData, brief],
  );
  const form = useForm({
    initialValues,
    validationSchema,
  });

  const [showModal, setShowModal] = useState<
    "CONFIRMATION" | "FEEDBACK" | undefined
  >(undefined);

  function executeOnSuccess(data: Communication) {
    // setBriefData({
    //   type: data.details.type as CommunicationType.DEM,
    //   layoutStructure: data.details.layout.layoutStructure,
    //   brief: data.details.brief,
    //   source: data.details.layoutSource as "MANUAL",
    // });
    setCommunication(data);
    onSuccess?.(data);
  }

  const { data: personas } = usePersonaListQuery();
  const defaultPersona = personas?.find((persona) => persona.default === true);

  const submitCreation = form.formProps.handleSubmit(
    (values: BriefDemForm & { files: FileList }, event) => {
      event?.preventDefault();
      console.log("values", values);

      const { files, ...valuesWithoutFiles } = values;

      useContentCreationSlice.setState({ briefData: valuesWithoutFiles });

      const details =
        valuesWithoutFiles.source === "MANUAL"
          ? {
              ...valuesWithoutFiles,
              layoutStructure: valuesWithoutFiles.layoutStructure,
              source: valuesWithoutFiles.source,
            }
          : {
              ...valuesWithoutFiles,
              layoutStructure: valuesWithoutFiles.layoutStructure,
              layoutHtml: valuesWithoutFiles.layoutHtml,
            };

      create(
        {
          personaId: defaultPersona?.id,
          details,
          files,
        },
        {
          onError: (e) => {
            console.log("error", e);
            setShowModal("FEEDBACK");
          },
          onSuccess: (data) => {
            executeOnSuccess(data);
          },
        },
      );
    },
  );

  const submitRegeneration = form.formProps.handleSubmit(
    (values: BriefDemForm & { files: FileList }, event) => {
      event?.preventDefault();
      const { files, ...valuesWithoutFiles } = values;

      useContentCreationSlice.setState({ briefData: valuesWithoutFiles });
      const commId = communication!.id;
      const details =
        valuesWithoutFiles.source === "MANUAL"
          ? {
              ...valuesWithoutFiles,
              layoutStructure: valuesWithoutFiles.layoutStructure,
              source: valuesWithoutFiles.source,
            }
          : {
              ...valuesWithoutFiles,
              layoutStructure: valuesWithoutFiles.layoutStructure,
              layoutHtml: valuesWithoutFiles.layoutHtml,
            };
      regenerate(
        {
          commId,
          input: {
            details,
            files,
          },
        },
        {
          onError: (e) => {
            console.log("error", e);
            setShowModal("FEEDBACK");
          },
          onSuccess: (data) => {
            executeOnSuccess(data);
          },
        },
      );
    },
  );

  const submit = communication?.id ? submitRegeneration : submitCreation;

  return {
    initialValues,
    isNewCommunication: !communication,
    showModal,
    setShowModal,
    form,
    submit,
    isPending,
    isSuccess,
  };
}
