import { useTranslate } from "@xtreamsrl/react-i18n";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { FormEventHandler, useCallback, useMemo, useState } from "react";
import type { TranslationKeys } from "src/_shared/assets/i18n";
import { CreatePersonaDraftParams } from "../apis/createPersonaDraft";
import { useCreatePersonaDraftMutation } from "../mutations/useCreatePersonaDraftMutation";
import { usePersonaListQuery } from "../queries/usePersonaListQuery";
import { Communication, Persona, PersonaDraft } from "../types";
import {
  CommEditingModal,
  PersonaSelector,
  PromptInput,
} from "./CommEditingModal";

export function CommEditingNewDraft({
  setSelectedDraft,
  communication,
}: {
  setSelectedDraft: (draft: PersonaDraft) => void;
  communication: Communication;
}) {
  const t = useTranslate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { id: commId } = communication;

  const handleOpen = useCallback(() => setModalIsOpen(true), []);
  const handleClose = useCallback(() => setModalIsOpen(false), []);

  const [newDraft, setNewDraft] = useState<PersonaDraft>();
  const [isGenerating, setIsGenerating] = useState(false);
  const handleNewDraft = useCallback(
    (draft: PersonaDraft) => {
      setIsGenerating(true);
      setNewDraft(draft);
    },
    [setIsGenerating, setNewDraft],
  );

  const { data: personas } = usePersonaListQuery();

  const handleStreamError = useCallback((error: unknown) => {
    console.error(error);
    setIsGenerating(false);
    setNewDraft(undefined);
  }, []);

  const handleStreamChunk = useCallback(() => {
    if (newDraft) setSelectedDraft(newDraft);
    setIsGenerating(false);
    setNewDraft(undefined);
    handleClose();
  }, [newDraft, handleClose, setSelectedDraft, setIsGenerating, setNewDraft]);

  const { mutateAsync: createDraft, isPending: isCreating } =
    useCreatePersonaDraftMutation({
      onStreamError: handleStreamError,
      onStreamChunk: handleStreamChunk,
    });

  const handleSubmit = useCallback(
    (input: CreatePersonaDraftParams) => {
      createDraft(input)
        .then(handleNewDraft)
        .catch((error) => {
          console.error(error);
        });
    },
    [createDraft, handleNewDraft],
  );

  const isLoading = isCreating || isGenerating;

  return (
    <Flex justifyContent="center">
      <Button
        size="md"
        variant="plain"
        leftIcon={<Icon name="Sparkles" />}
        onClick={handleOpen}
        color="grey"
      >
        {t("commEditing.newDraftButton" satisfies TranslationKeys)}
      </Button>
      <NewDraftModal
        isOpen={modalIsOpen}
        onClose={handleClose}
        isLoading={isLoading}
      >
        {personas && (
          <NewDraftForm
            commId={commId}
            onSubmit={handleSubmit}
            onCancel={handleClose}
            personas={personas}
            isLoading={isLoading}
          />
        )}
      </NewDraftModal>
    </Flex>
  );
}

function NewDraftModal({
  isOpen,
  onClose,
  children,
  isLoading,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  isLoading: boolean;
}) {
  const t = useTranslate();
  return (
    <CommEditingModal
      canClose={!isLoading}
      isOpen={isOpen}
      onClose={onClose}
      title={t("commEditing.newDraftModal.title" satisfies TranslationKeys)}
      paragraph={t(
        "commEditing.newDraftModal.paragraph" satisfies TranslationKeys,
      )}
    >
      {children}
    </CommEditingModal>
  );
}

function NewDraftForm({
  commId,
  onSubmit,
  onCancel,
  personas,
  isLoading,
}: {
  commId: string;
  onSubmit: (input: CreatePersonaDraftParams) => void;
  onCancel: () => void;
  personas: Persona[];
  isLoading: boolean;
}) {
  const initialPersona = useMemo(
    () => personas.find((persona) => persona.default),
    [personas],
  );
  const [persona, setPersona] = useState(initialPersona);
  const [prompt, setPrompt] = useState("");
  const t = useTranslate();

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();
      if (!persona) return;
      onSubmit({
        personaId: persona.id,
        directions: prompt,
        commId,
      });
    },
    [persona, onSubmit, prompt, commId],
  );

  return (
    <form onSubmit={handleSubmit}>
      <PersonaSelector
        label={t(
          "commEditing.newDraftModal.form.personaSelector.label" satisfies TranslationKeys,
        )}
        placeholder={t(
          "commEditing.newDraftModal.form.personaSelector.placeholder" satisfies TranslationKeys,
        )}
        selectedPersona={persona}
        setSelectedPersona={setPersona}
        disabled={isLoading}
      />
      <PromptInput
        label={t(
          "commEditing.newDraftModal.form.promptInput.label" satisfies TranslationKeys,
        )}
        placeholder={t(
          "commEditing.newDraftModal.form.promptInput.placeholder" satisfies TranslationKeys,
        )}
        prompt={prompt}
        setPrompt={setPrompt}
        disabled={isLoading}
      />
      <Flex justifyContent="space-between">
        <Button
          variant="outlined"
          type="button"
          color="grey"
          onClick={onCancel}
          disabled={isLoading}
        >
          {t(
            "commEditing.newDraftModal.form.cancelButton.label" satisfies TranslationKeys,
          )}
        </Button>
        <Button
          variant="filled"
          type="submit"
          disabled={isLoading}
          loading={isLoading}
        >
          {t(
            "commEditing.newDraftModal.form.submitButton.label" satisfies TranslationKeys,
          )}
        </Button>
      </Flex>
    </form>
  );
}
