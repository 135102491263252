import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { useCallback, useState } from "react";
import { useUpdateCommMutation } from "../mutations/useUpdateCommMutation";
import { useCommDetailsQuery } from "../queries/useCommDetailsQuery";

export function CommNameInput({
  commId,
  disabled,
}: {
  commId: string;
  disabled?: boolean;
}) {
  const { data: comm } = useCommDetailsQuery(commId);

  if (!comm) return null;

  return (
    <Flex
      direction="row"
      gap="sm-2"
      alignItems="center"
      width="100%"
      padding="sm-2"
    >
      <NameInput initialValue={comm.name} commId={commId} disabled={disabled} />
    </Flex>
  );
}

const StyledInput = styled("input")(({ theme }) => ({
  position: "relative",
  fontFamily: theme.typography["body/xxl/semibold"].fontFamily,
  fontSize: theme.typography["body/xxl/semibold"].fontSize,
  fontWeight: theme.typography["body/xxl/semibold"].fontWeight,
  lineHeight: theme.typography["body/xxl/semibold"].lineHeight,
  border: "none",
  background: "transparent",
  padding: 0,
  paddingInline: "1px",
  margin: 0,
  textOverflow: "ellipsis",
  width: "100%",
  outline: "none",
  borderRadius: "3px",
  "&:hover": {
    outline: `2px solid ${theme.palette.primary.main}`,
  },
  "&:focus-within": {
    backgroundColor: theme.palette.brand[2],
    outline: `2px solid ${theme.palette.primary.main}`,
  },
  "&:disabled": {
    cursor: "default",
    color: theme.palette.text.primary,
  },
  "&:-webkit-autofill": {
    "-webkit-box-shadow": "0 0 0 1000px white inset !important",
    "-webkit-text-fill-color": `${theme.palette.text.primary} !important`,
  },
}));

function NameInput({
  initialValue,
  commId,
  disabled,
}: {
  initialValue: string;
  commId: string;
  disabled?: boolean;
}) {
  const [value, setValue] = useState(initialValue);

  const { mutate } = useUpdateCommMutation();

  const saveChange = useCallback(() => {
    mutate({
      commId,
      changes: {
        name: value,
      },
    });
  }, [mutate, commId, value]);

  function handleSave() {
    if (value === "") {
      setValue(initialValue);
    } else {
      saveChange();
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    switch (e.key) {
      case "Escape":
        setValue(initialValue);
        break;
      case "Enter":
        e.currentTarget.blur();
        break;
    }
  }

  return (
    <StyledInput
      name="commId"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleSave}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      title={value}
    />
  );
}
