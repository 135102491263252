import { useTranslate } from "@xtreamsrl/react-i18n";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { Tooltip } from "@xtreamsrl/react-ui-kit/Tooltip";
import { LockIcon, Upload } from "lucide-react";
import { memo, useCallback, useMemo, useState } from "react";
import { FilePreview } from "./FilePreview";

const HiddenFileInput = styled("input")(() => ({
  opacity: 0,
  width: 0,
}));

const AttachmentsLabel = styled("label")(({ theme }) => ({
  ...theme.typography["body/base/medium"],
}));

const LabelContainer = styled("label")(() => ({
  cursor: "pointer",
  gap: "8px",
  color: "#374151",
  width: "100%",
}));

const FilesContainer = styled(Flex)(({ theme }) => ({
  borderRadius: "0.5rem",
  background: "white",
  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
  ":focus-within": {
    outline: `1px solid ${theme.palette.brand[9]}`,
    boxShadow: "0px 0px 0px 3px rgba(95, 77, 219, 0.2)",
  },
}));

const InfoIcon = styled("div")(() => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ErrorMessage = styled("p")(({ theme }) => ({
  height: "16px",
  color: theme.palette.error.main,
  ...theme.typography["body/sm/regular"],
  margin: theme.spacing("sm-2", 0),
}));

type FormFileInputProps = {
  onFilesChange: (files: File[]) => void;
  errors: string | undefined;
  allowedMimeTypes: string[];
};

export const FormFileInput = memo(function FormFileInput({
  onFilesChange,
  errors,
  allowedMimeTypes,
}: FormFileInputProps) {
  const t = useTranslate();
  const [files, setFiles] = useState<File[]>([]);

  const allowedMimeTypesString = useMemo(
    () => allowedMimeTypes.join(","),
    [allowedMimeTypes],
  );

  const handleAddFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;
      const newFiles = e.target.files;
      const nextFiles = [...files, ...Array.from(newFiles)];
      setFiles(nextFiles);
      onFilesChange(nextFiles);
      // Allows removal and re-upload of the same file
      e.target.value = "";
    },
    [files, onFilesChange],
  );

  const handleRemoveFile = useCallback(
    (file: File) => {
      if (!file) return;
      const nextFiles = files.filter((f) => f !== file);
      setFiles(nextFiles);
      onFilesChange(nextFiles);
    },
    [files, onFilesChange],
  );

  return (
    <Flex gap={"sm-6"} direction={"column"}>
      <Flex alignItems="center" gap="sm-4">
        <AttachmentsLabel htmlFor="files">
          {t("brief.attachments.label")}
        </AttachmentsLabel>
        <InfoIcon style={{ cursor: "pointer" }}>
          <Tooltip placement="right" text={t("brief.attachments.info")}>
            <LockIcon width={16} height={16} />
          </Tooltip>
        </InfoIcon>
      </Flex>
      <LabelContainer htmlFor="files">
        <FilesContainer alignItems="center" gap="sm-4" padding="sm-8">
          <Flex flexWrap="wrap" flex="1" style={{ columnGap: "0.5rem" }}>
            {files.map((file) => (
              <FilePreview
                key={file.name}
                file={file}
                onRemove={handleRemoveFile}
                allowedMimeTypes={allowedMimeTypes}
              />
            ))}
          </Flex>
          <Flex
            mt={"auto"}
            mb={"0"}
            justifyContent="center"
            alignItems="center"
          >
            <Upload width="16px" height="16px" />
            <HiddenFileInput
              type="file"
              id="files"
              accept={allowedMimeTypesString}
              onChange={handleAddFile}
              multiple
            />
          </Flex>
        </FilesContainer>
      </LabelContainer>
      <ErrorMessage>{errors ? errors : ""}</ErrorMessage>
    </Flex>
  );
});
