type Config = {
  baseUrl: string;
  isTokenExpired(token: string): Promise<boolean>;
  refreshToken(): Promise<string>;
  getToken(): Promise<string> | string;
};

let config: Config = {
  baseUrl: "",
  getToken() {
    console.warn("mocked getToken called");
    return "";
  },
  isTokenExpired(): Promise<boolean> {
    console.warn("mocked isTokenExpired called");
    return Promise.resolve(false);
  },
  refreshToken(): Promise<string> {
    console.warn("mocked refreshToken called");
    return Promise.resolve("");
  },
};

export function setConfig(newConfig: Partial<Config>) {
  config = {
    ...config,
    ...newConfig,
  };
}

const queryStringify = (
  params: Record<string, string | number | boolean>,
  options: {
    skipNull: boolean;
    arraySeparator: string;
    encode: boolean;
  } = {
    skipNull: true,
    arraySeparator: ",",
    encode: true,
  },
) =>
  Object.keys(params)
    .filter(
      (k) =>
        params[k] !== undefined &&
        ((options.skipNull && params[k] !== null) || !options.skipNull),
    )
    .map((key) => {
      const value = params[key];
      let result;
      if (Array.isArray(value)) {
        result = value.join(options.arraySeparator);
      } else {
        result = value;
      }
      return `${options.encode ? encodeURIComponent(key) : key}=${
        options.encode ? encodeURIComponent(result) : result
      }`;
    })
    .join("&");

async function internalFetch({
  urlOrPath,
  method,
  body,
  headers = {},
  queryParams = {},
  extractJson = true,
  signal,
  multipart = false,
}: {
  urlOrPath: string;
  method: "GET" | "POST" | "PATCH" | "DELETE" | "PUT";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  headers?: Record<string, string>;
  queryParams?: Record<string, string | number>;
  extractJson?: boolean;
  signal?: AbortSignal;
  multipart?: boolean;
}) {
  // console.log(`Internal fetch: ${method} ${urlOrPath}`);

  const token = await config.getToken();
  if (token) {
    await config.isTokenExpired(token);
  }
  // console.log(`Token is defined: ${!!token}`);

  const h = new Headers(headers);
  if (token) h.append("Authorization", `Bearer ${token}`);
  if (body && !multipart) {
    h.append("Content-type", "application/json; charset=UTF-8");
  }

  const init: RequestInit = {
    method: method,
    headers: h,
    credentials: "include",
    signal,
  };

  let res: Response;
  const paramsPresents = queryParams && Object.keys(queryParams).length > 0;

  const params = paramsPresents ? `?${queryStringify(queryParams)}` : "";

  const completeUrl = urlOrPath.startsWith("http")
    ? `${urlOrPath}${params}`
    : `${config.baseUrl}${urlOrPath}${params}`;

  if (body) {
    res = await fetch(completeUrl, {
      ...init,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      body: multipart ? body : JSON.stringify({ ...body }),
    });
  } else {
    res = await fetch(completeUrl, init);
  }

  if (res.ok) {
    return extractJson ? res.json() : res;
  } else {
    throw {
      code: `${res.status}`,
      message: res.statusText,
    };
  }
}

const newFetch = {
  async post<T>(
    urlOrPath: string,
    body: object,
    options?: {
      extractJson?: boolean;
      headers?: Record<string, string>;
      multipart?: boolean;
    },
  ) {
    return internalFetch({
      urlOrPath,
      method: "POST",
      body: body,
      extractJson: options?.extractJson,
      headers: options?.headers,
      multipart: options?.multipart,
    }) as Promise<T>;
  },
  async get<T>(
    urlOrPath: string,
    queryParams?: Record<string, string | number>,
    extractJson?: boolean,
    signal?: AbortSignal,
  ) {
    return internalFetch({
      urlOrPath,
      method: "GET",
      queryParams: queryParams,
      extractJson,
      signal,
    }) as Promise<T>;
  },
  async patch<T>(urlOrPath: string, body: object, extractJson: boolean) {
    return internalFetch({
      urlOrPath,
      method: "PATCH",
      body: body,
      extractJson: extractJson,
    }) as Promise<T>;
  },
  async delete<T>(
    urlOrPath: string,
    { body, extractJson }: { body?: object; extractJson?: boolean },
  ) {
    return internalFetch({
      urlOrPath,
      method: "DELETE",
      body: body,
      extractJson: extractJson,
    }) as Promise<T>;
  },
  async put<T>(
    urlOrPath: string,
    body: object,
    options: { extractJson?: boolean },
  ) {
    return internalFetch({
      urlOrPath,
      method: "PUT",
      body: body,
      extractJson: options.extractJson,
    }) as Promise<T>;
  },
};

export default newFetch;
