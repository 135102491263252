import { moveSectionTitleOutsideComponents } from "src/templates/utils";
import { default as fetch } from "src/_shared/fetch";
import { BriefDemForm, Communication } from "../types";

export type CreateCommunicationInput = {
  name?: string;
  personaId?: string;
  files?: FileList;
  details: BriefDemForm;
};

export async function createCommunicationAPI(
  input: CreateCommunicationInput,
): Promise<Communication>;

export async function createCommunicationAPI(
  input: CreateCommunicationInput,
): Promise<Communication> {
  const layoutStructure =
    input.details.source === "MANUAL"
      ? moveSectionTitleOutsideComponents(input.details.layoutStructure)
      : input.details.layoutStructure;

  const files = input.files;

  const formData = new FormData();
  formData.append("personaId", input.personaId ?? "");
  formData.append(
    "details",
    JSON.stringify({ ...input.details, layoutStructure }),
  );

  if (files && files.length > 0) {
    Array.from(files).forEach((file) => {
      formData.append("files", file);
    });
  }

  const communication = await fetch.post<Communication>(
    "/communications",
    formData,
    {
      multipart: true,
    },
  );

  return communication;
}
