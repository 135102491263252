import { Template, isManualTemplate } from "src/templates/types";
import { useCallback, useMemo } from "react";
import { useCommunicationType } from "../hooks/useCommunicationType";
import { SidePanel } from "@xtreamsrl/react-ui-kit/SidePanel";
// import { useBriefData } from "../hooks/useBriefData";
import { Intent } from "../views/SelectLayout";
import { useTranslate } from "@xtreamsrl/react-i18n";
import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import { Divider } from "@xtreamsrl/react-ui-kit/Divider";
import { HTMLRenderer } from "./HTMLRenderer";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { useBriefData } from "../hooks/useBriefData";
import { useStepper } from "../hooks/useStepper";
import { BriefData, CommunicationType } from "../types";
import { HTMLPreviewBox } from "../../_shared/components/HTMLPreviewBox";

export function ImportedLayoutModal({
  templates,
  templateId,
  closeModal,
  modalIsOpen,
  // intent,
}: {
  templates: Template[];
  templateId: string | null;
  closeModal: () => void;
  modalIsOpen: boolean;
  intent: Intent | null;
}) {
  const { next } = useStepper();
  const communicationType = useCommunicationType();
  const t = useTranslate();

  if (!communicationType) throw new Error("Communication type not set");

  function isImportedBriefData(
    data: BriefData | undefined,
  ): data is Extract<BriefData, { source: "IMPORTED" }> {
    return Boolean(data && data.source === "IMPORTED");
  }

  const [briefData, setBriefData] = useBriefData();

  const template = useMemo(() => {
    if (templateId) {
      const found = templates.find((t) => t.id === templateId);
      if (found) {
        if (isManualTemplate(found)) throw new Error("Template is manual");
        return found;
      }
    }

    // Fallback: if no template is found, use data from briefData if it’s the IMPORTED variant
    if (isImportedBriefData(briefData)) {
      return {
        layoutHtml: briefData.layoutHtml,
        structure: briefData.layoutStructure,
      };
    }
    return undefined;
  }, [templates, templateId, briefData]);

  if (!template)
    throw new Error("Template not found and no previous layout data available");

  if (!template) throw new Error("Template not found");

  const handleSubmit = useCallback(() => {
    setBriefData({
      source: "IMPORTED",
      type: communicationType as CommunicationType.DEM,
      brief: briefData?.brief ?? [],
      layoutStructure: template.structure ?? briefData?.layoutStructure,
      layoutHtml:
        template.layoutHtml ??
        (isImportedBriefData(briefData) ? briefData.layoutHtml : null),
    });
    next();
    // do not call closeModal because that resets the brief data
  }, [next, setBriefData, briefData, communicationType, template]);

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal /* setBriefData, briefData, communicationType */]);

  return (
    <SidePanel open={modalIsOpen} onClose={handleClose} anchor="right">
      <SidePanel.Content height="100%" width="80vw">
        <form
          onSubmit={handleSubmit}
          style={{
            height: "100%",
          }}
        >
          <Flex height="100%" direction="column" gap="md-1">
            <Typography variant="header/lg/bold">
              {t("layout.creation.title")}
            </Typography>
            <Divider />
            <HTMLPreviewBox>
              <HTMLRenderer>{template.layoutHtml}</HTMLRenderer>
            </HTMLPreviewBox>
            <Divider />
            <Flex justifyContent="flex-end" gap="md-1">
              <Button
                type="button"
                variant="outlined"
                onClick={closeModal}
                color="grey"
              >
                {t("template.creation.cancel")}
              </Button>
              <Button
                testId="create-template"
                type="submit"
                variant="filled"
                loading={false}
              >
                {t("template.creation.submit")}
              </Button>
            </Flex>
          </Flex>
        </form>
      </SidePanel.Content>
    </SidePanel>
  );
}
