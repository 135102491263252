import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { PersonaDraft } from "../types";
import { CommDraftCard } from "./CommDraftCard";
import { Box } from "@xtreamsrl/react-ui-kit/Box";
import { styled } from "@xtreamsrl/react-ui-kit/styles";

const gradientColorStart = "rgba(244,245,252,1)";
const gradientColorStop = "rgba(244,245,252,0)";
const transparentNodeStyles = {
  content: '""',
  position: "absolute",
  left: 0,
  right: 0,
  height: "20px", // Adjust height as needed
  pointerEvents: "none", // Ensures overlay does not interfere with scrolling
  zIndex: 10,
};

const TransparentOverlay = styled(Box)({
  flex: 1,
  height: "100%",
  position: "relative",
  overflow: "hidden",
  "&::before": {
    ...transparentNodeStyles,
    top: 0,
    background: `linear-gradient(to bottom, ${gradientColorStart}, ${gradientColorStop})`,
  },
  "&::after": {
    ...transparentNodeStyles,
    bottom: 0,
    background: `linear-gradient(to top, ${gradientColorStart}, ${gradientColorStop})`,
  },
});

const ScrollableContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  inset: 0,
  height: "100%",
  overflow: "auto",
  padding: theme.spacing("md-2"),
  paddingTop: theme.spacing("sm-5"),
  paddingLeft: 0,
  // add opacity mask overlay with pseudo selector
}));

export function CommDraftList(props: {
  commId: string;
  selectedDraft?: PersonaDraft;
  personaDrafts: PersonaDraft[];
  setSelectedDraft: (draft: PersonaDraft) => void;
}) {
  const { personaDrafts, selectedDraft, setSelectedDraft, commId } = props;
  return (
    <TransparentOverlay>
      <ScrollableContainer>
        <Flex direction="column" gap="sm-8" p={3}>
          {personaDrafts.map((draft) => (
            <CommDraftCard
              key={draft.id}
              draft={draft}
              commId={commId}
              selectedDraft={selectedDraft}
              setSelectedDraft={setSelectedDraft}
              personaDrafts={personaDrafts}
            />
          ))}
        </Flex>
      </ScrollableContainer>
    </TransparentOverlay>
  );
}
