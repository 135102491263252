import { FormTextInput } from "@xtreamsrl/react-form-input-kit/FormTextInput";
import { FormProvider, ReturnUserForm } from "@xtreamsrl/react-forms";
import { useTranslate } from "@xtreamsrl/react-i18n";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { FormEventHandler, useCallback, useState } from "react";
import { ConfirmationWithFeedbackModal } from "src/_shared/components/ConfirmationWithFeedbackModal";
import { GenerationLoadingModal } from "../components/GenerationLoadingModal";
import { StepInfoBox } from "../components/StepInfoBox";
import { StepLayout } from "../components/StepLayout";
import { useBriefData } from "../hooks/useBriefData";
import {
  ALLOWED_MIME_TYPES,
  useCreateCommunication,
} from "../hooks/useCreateCommunication";
import { useStepper } from "../hooks/useStepper";
import { BriefDemForm } from "../types";
import { FormFileInput } from "./BriefFileUpload/FormFileInput";

export function WriteBrief() {
  const { previous } = useStepper();

  const t = useTranslate();

  const [isLoading, setIsLoading] = useState(false);

  const handleError = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  const [savedBrief] = useBriefData();
  const defaultBrief = [
    { question: t("brief.questions.0.label"), answer: "" },
    { question: t("brief.questions.1.label"), answer: "" },
  ];
  const { form, submit, showModal, setShowModal, initialValues } =
    useCreateCommunication({
      onStreamError: handleError,
      onError: handleError,
      brief: savedBrief?.brief?.length ? savedBrief.brief : defaultBrief,
    });

  const handleSubmit = useCallback<FormEventHandler>(
    (e) => {
      setIsLoading(true);
      submit(e).catch(console.error);
    },
    [submit, setIsLoading],
  );
  const canSubmit = form.formState.isValid;

  return (
    <>
      <GenerationLoadingModal open={isLoading} />
      <FormProvider {...form.formProps}>
        <form onSubmit={handleSubmit}>
          <StepLayout
            title={t("brief.header")}
            leftPane={<LeftPane />}
            rightPane={
              <RightPane
                initialValues={initialValues}
                isLoading={isLoading}
                form={form}
              />
            }
            buttons={
              <>
                <Button
                  variant="filled"
                  color="white"
                  onClick={previous}
                  disabled={isLoading}
                >
                  {t("brief.goBack")}
                </Button>
                <Button
                  type="submit"
                  variant="filled"
                  color="brand"
                  disabled={!canSubmit || isLoading}
                >
                  {t("brief.goNext")}
                </Button>
              </>
            }
          />
        </form>
      </FormProvider>
      <ConfirmationWithFeedbackModal
        state={showModal}
        confirmationHeaderKey="brief.creationConfirmation"
        feedbackText={{
          headerKey: "brief.creationFailureHeader",
          contentKey: "brief.creationFailureContent",
        }}
        onCancel={() => {
          setShowModal(undefined);
        }}
        onConfirm={() => {
          submit().catch((err) => {
            console.error("Error creating communication", err);
          });
          setShowModal(undefined);
        }}
      />
    </>
  );
}

function LeftPane() {
  const t = useTranslate();
  return (
    <StepInfoBox
      illustrationProps={{
        iconProps: {
          name: "List",
        },
      }}
    >
      <Typography variant="body/xl/regular" color="inherit">
        {t("brief.indications.1")}
      </Typography>
      <Typography variant="body/xl/regular" color="inherit">
        {t("brief.indications.2")}
      </Typography>
    </StepInfoBox>
  );
}

const FieldContainer = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing("md-4"),
  height: "100%",
  justifyContent: "center",
  ".MuiFormHelperText-root": {
    display: "none",
  },
  ".MuiInputBase-inputMultiline": {
    resize: "vertical",
    padding: theme.spacing("sm-7"),
  },
  ".MuiInputBase-root:has(.MuiInputBase-inputMultiline)": {
    padding: 2 + "px !important",
  },
}));

function RightPane({
  initialValues,
  isLoading,
  form,
}: {
  initialValues: BriefDemForm;
  isLoading?: boolean;
  form: ReturnUserForm<BriefDemForm & { files: FileList }>;
}) {
  const {
    setValue,
    formState: { errors },
  } = form.formProps;

  const onFilesChange = useCallback(
    (files: File[]) => {
      if (!files) return;

      const dataTransfer = new DataTransfer();
      files.forEach((file) => {
        dataTransfer.items.add(file);
      });

      setValue("files", dataTransfer.files, { shouldValidate: true });
    },
    [setValue],
  );

  return (
    <FieldContainer>
      {initialValues.brief.map((question, index) => (
        <FormTextInput
          key={index}
          required={true}
          multiline={true}
          label={question.question}
          placeholder={question.question}
          name={`brief.${index}.answer`}
          minRows={6}
          disabled={isLoading}
          {...{ maxRows: 6 }}
        />
      ))}
      <FormFileInput
        onFilesChange={onFilesChange}
        errors={errors.files?.message}
        allowedMimeTypes={ALLOWED_MIME_TYPES}
      />
    </FieldContainer>
  );
}
