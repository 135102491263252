import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { FileIcon, X } from "lucide-react";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { useEffect, useState } from "react";

import Skeleton from "@mui/material/Skeleton";

const FileNameSpan = styled("span")(({ theme }) => ({
  fontSize: theme.typography["body/sm/regular"].fontSize,
  color: "#374151",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "200px",
}));

export function FilePreview({
  file,
  onRemove,
  allowedMimeTypes,
}: {
  file: File;
  onRemove: (file: File) => void;
  allowedMimeTypes: string[];
}) {
  const filePreview =
    file.type === "application/pdf" ? (
      <FileIcon width="18px" height="18px" color="#6b7280" />
    ) : (
      <PreviewImage file={file} />
    );

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      gap="8px"
      padding="8px"
      border={`1px solid ${allowedMimeTypes.includes(file.type) ? "#d1d5db" : "#ef4444"}`}
      marginBottom="10px"
      position="relative"
      style={{
        borderRadius: "6px",
        backgroundColor: "#f9fafb",
        maxWidth: "fit-content",
      }}
    >
      {filePreview}
      <FileNameSpan>{file.name}</FileNameSpan>
      <Button
        variant="outlined"
        color="grey"
        size="sm"
        onlyIcon
        icon={
          <Icon>
            <X />
          </Icon>
        }
        style={{ padding: "0.25rem" }}
        onClick={(e) => {
          e.preventDefault();
          onRemove(file);
        }}
      />
    </Flex>
  );
}

function PreviewImage({ file }: { file: File }) {
  const [urlObject, setUrlObject] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const url = URL.createObjectURL(file);
    setUrlObject(url);

    return () => {
      URL.revokeObjectURL(url);
      setUrlObject(null);
    };
  }, [file]);

  return (
    <>
      {isLoading ? (
        <Skeleton variant="rectangular" width={20} height={20} />
      ) : null}
      <img
        alt={file.name}
        src={urlObject ?? ""}
        height="20px"
        width="20px"
        style={{ borderRadius: "3px", display: isLoading ? "none" : "block" }}
        onLoad={() => setIsLoading(false)}
      />
    </>
  );
}
