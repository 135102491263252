import { pages } from "@reaidy/pages";
import { useQuery } from "@tanstack/react-query";
import { useTranslate } from "@xtreamsrl/react-i18n";
import { useBrowserNavigation } from "@xtreamsrl/react-routing";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { SelectBasic } from "@xtreamsrl/react-ui-kit/SelectBasic";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import {
  FileDownloadIcon,
  PencilEdit02Icon,
  PropertyEditIcon,
} from "hugeicons-react";
import { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useParams } from "react-router-dom";
import { CommDraftList } from "../components/CommDraftList";
import { EditingLayout } from "../components/CommEditingLayout";
import { CommEditingNewDraft } from "../components/CommEditingNewDraft";
import { CommNameInput } from "../components/CommNameInput";
import { EditBriefModal } from "../components/EditBriefModal";
import { EditLayoutModal } from "../components/EditLayoutPanel";
import { NewDraftEditor } from "../components/NewDraftEditor";
import { useChangeDraftPersonaMutation } from "../mutations/useChangeDraftPersonaMutation";
import { getCommDetailsQuery } from "../queries/useCommDetailsQuery";
import { useCommPersonaDraftQuery } from "../queries/useCommPersonaDraftQuery";
import { usePersonaListQuery } from "../queries/usePersonaListQuery";
import { useContentCreationSlice } from "../slice/contentCreationSlice";
import { Communication, PersonaDraft } from "../types";
import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import * as htmlToImage from "html-to-image";

export function CommEditing() {
  const { communicationId } = useParams<{ communicationId: string }>();
  const { data } = useQuery({
    ...getCommDetailsQuery(communicationId!),
    enabled: !!communicationId,
  });
  const slice = useContentCreationSlice((state) => state.communication);
  const communication = data || slice;

  if (!communication) return null;
  return <CommEditingView communication={communication} />;
}

function CommEditingView({ communication }: { communication: Communication }) {
  const commId = communication.id;
  const { data: personaDrafts = communication.personaDrafts } = useQuery({
    ...getCommDetailsQuery(commId),
    select: (data) => data?.personaDrafts,
  });

  const [selectedDraft, setSelectedDraft] = useState<PersonaDraft | undefined>(
    personaDrafts[0],
  );

  return (
    <EditingLayout
      heading={
        <Header
          commId={commId}
          communication={communication}
          selectedDraft={selectedDraft ?? personaDrafts[0]}
          setSelectedDraft={setSelectedDraft}
        />
      }
      leftPanel={
        selectedDraft && (
          <LeftPanel
            communication={communication}
            selectedDraft={selectedDraft}
          />
        )
      }
      rightPanel={
        <RightPanel
          communication={communication}
          selectedDraft={selectedDraft}
          setSelectedDraft={setSelectedDraft}
          personaDrafts={personaDrafts}
        />
      }
    />
  );
}

function Header({
  commId,
  communication,
  selectedDraft,
  setSelectedDraft,
}: {
  commId: string;
  communication: Communication;
  selectedDraft: PersonaDraft;
  setSelectedDraft: (draft: PersonaDraft) => void;
}) {
  const t = useTranslate();
  const { goTo } = useBrowserNavigation();
  const draftId = selectedDraft?.id;

  const { data: draft } = useCommPersonaDraftQuery({
    commId,
    draftId,
  });

  const isLoading = draft?.status === "DRAFT_LOADING";
  const isSaving = draft?.status === "DRAFT_SAVING";

  const buttonsDisabled = isLoading || isSaving;

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      gap="sm-4"
      flexWrap="wrap"
      flex={1}
    >
      <Flex flex={1} flexBasis="580px">
        <CommNameInput commId={commId} />
      </Flex>
      <Flex gap="sm-4" flexWrap="wrap">
        <EditBriefButton
          commId={commId}
          disabled={buttonsDisabled}
          selectedDraft={selectedDraft}
          setSelectedDraft={setSelectedDraft}
        />
        {communication.details.layoutSource === "MANUAL" && (
          <EditLayoutButton
            disabled={buttonsDisabled}
            communication={communication}
            setSelectedDraft={setSelectedDraft}
          />
        )}
        <Button color="brand" variant="tinted" onClick={() => goTo(pages.home)}>
          {t("commEditing.closeButton")}
        </Button>
      </Flex>
    </Flex>
  );
}

const ExportSelect = styled(SelectBasic)(({ theme }) => ({
  "& > *": {
    backgroundColor: theme.palette.brand[9],
    color: theme.palette.brand[1],
    "&:before, &:after": {
      borderBottom: "1px solid transparent",
    },
    svg: {
      color: theme.palette.brand[1],
    },
    "&:hover": {
      backgroundColor: theme.palette.brand[10],
      borderColor: theme.palette.grey[8],
    },
    "&.Mui-focused": {
      backgroundColor: theme.palette.brand[9],
    },
    "&.Mui-focused > *": {
      backgroundColor: "inherit",
    },
  },
}));

function LeftPanel({
  selectedDraft,
  communication,
}: {
  selectedDraft: PersonaDraft;
  communication: Communication;
}) {
  const t = useTranslate();
  const draftId = selectedDraft?.id;
  const commId = communication.id;
  const { data: draft } = useCommPersonaDraftQuery({
    commId,
    draftId,
  });
  const isLoading = draft?.status === "DRAFT_LOADING";
  const isSaving = draft?.status === "DRAFT_SAVING";
  const isSaved = draft?.status === "DRAFT_SAVED";

  const buttonsDisabled = isLoading || isSaving;

  const exportsOptions = [t("commEditing.exportButton"), "html", "png"];

  const downloadCommunication = (dataUrl: string, extension: string) => {
    const link = document.createElement("a");
    link.download = `${communication.name}.${extension}`;
    link.href = dataUrl;
    link.click();
  };

  function handleExport(extension: string) {
    switch (extension) {
      case "html":
        exportHtml();
        break;
      case "png":
        exportPng();
    }
  }

  const exportHtml = () => {
    const html =
      document.querySelector("#editor")?.firstElementChild?.shadowRoot
        ?.innerHTML;
    if (!html) return;

    const noEditableHtml = html
      .replace(/data-content-editable="true"/g, 'data-content-editable="false"')
      .replace(/contenteditable="true"/g, 'contenteditable="false"');

    downloadCommunication(
      `data:text/plain;charset=utf-8,${encodeURIComponent(noEditableHtml)}`,
      "html",
    );
  };

  const exportPng = () => {
    const html = document.querySelector("#editor")
      ?.firstElementChild as HTMLElement;
    if (!html) return;

    htmlToImage
      .toPng(html)
      .then((dataUrl) => {
        downloadCommunication(dataUrl, "png");
      })
      .catch((error) => {
        console.error("Error exporting:", error);
        console.error("Failed to export image");
      });
  };

  return (
    <Flex flex={1} direction="column" gap="md-1">
      <Flex gap="md-1" justifyContent="space-between">
        <PersonaSelector
          disabled={buttonsDisabled}
          communication={communication}
          selectedDraft={selectedDraft}
        />
        <Flex gap="sm-2">
          <ExportSelect
            name="exports"
            value={exportsOptions[0]}
            placeholder={t("commEditing.exportButton")}
          >
            {exportsOptions.map((option) => {
              if (option === t("commEditing.exportButton")) {
                return (
                  <option value={option} style={{ display: "none" }}>
                    {option}
                  </option>
                );
              } else {
                return (
                  <SelectBasic.Option
                    key={option}
                    value={option}
                    onClick={() => handleExport(option)}
                    endAddOn={
                      <Button
                        variant="outlined"
                        size="sm"
                        icon={<Icon children={<FileDownloadIcon />} />}
                        onlyIcon
                      />
                    }
                  >
                    {option}
                  </SelectBasic.Option>
                );
              }
            })}
          </ExportSelect>
        </Flex>
      </Flex>
      <ErrorBoundary fallbackRender={renderEditorError}>
        {draft && (
          <NewDraftEditor
            draft={draft}
            communication={communication}
            isLoading={isLoading}
            isSaved={isSaved}
            isSaving={isSaving}
          />
        )}
      </ErrorBoundary>
    </Flex>
  );
}

function renderEditorError({ error }: FallbackProps) {
  return (
    <div role="alert">
      <p>Error</p>
      <pre>
        {error instanceof Error
          ? error.message
          : JSON.stringify(error, null, 2)}
      </pre>
    </div>
  );
}

function RightPanel({
  communication,
  selectedDraft,
  setSelectedDraft,
  personaDrafts,
}: {
  communication: Communication;
  selectedDraft?: PersonaDraft;
  setSelectedDraft: (draft: PersonaDraft) => void;
  personaDrafts: PersonaDraft[];
}) {
  const commId = communication.id;
  return (
    <Flex direction="column" gap="sm-4" height="100%">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        pt="md-2"
        pr="md-4"
      >
        <Typography variant="body/xl/semibold">Varianti</Typography>
        <CommEditingNewDraft
          setSelectedDraft={setSelectedDraft}
          communication={communication}
        />
      </Flex>
      <CommDraftList
        commId={commId}
        selectedDraft={selectedDraft}
        personaDrafts={personaDrafts}
        setSelectedDraft={setSelectedDraft}
      />
    </Flex>
  );
}

const InnerGreyButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[12],
  svg: {
    color: "inherit",
  },
}));

function EditBriefButton({
  commId,
  setSelectedDraft,
  disabled,
}: {
  commId: string;
  selectedDraft: PersonaDraft;
  setSelectedDraft: (draft: PersonaDraft) => void;
  disabled?: boolean;
}) {
  const t = useTranslate();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const closeModal = useCallback(() => setModalIsOpen(false), [setModalIsOpen]);
  const openModal = useCallback(() => setModalIsOpen(true), [setModalIsOpen]);

  return (
    <>
      <InnerGreyButton
        onClick={openModal}
        disabled={disabled}
        color="grey"
        size="lg"
        variant="outlined"
        leftIcon={<Icon children={<PencilEdit02Icon />} />}
      >
        {t("commEditing.editBrief.title")}
      </InnerGreyButton>
      {modalIsOpen && (
        <EditBriefModal
          commId={commId}
          isOpen={modalIsOpen}
          handleClose={closeModal}
          setSelectedDraft={setSelectedDraft}
        />
      )}
    </>
  );
}

function EditLayoutButton({
  disabled,
  communication,
  setSelectedDraft,
}: {
  disabled?: boolean;
  communication: Communication;
  setSelectedDraft: (draft: PersonaDraft) => void;
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = useCallback(() => setModalIsOpen(true), []);
  const closeModal = useCallback(() => setModalIsOpen(false), []);
  const t = useTranslate();

  return (
    <>
      <EditLayoutModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        communication={communication}
        setSelectedDraft={setSelectedDraft}
      />
      <InnerGreyButton
        onClick={openModal}
        disabled={disabled}
        color="grey"
        size="lg"
        variant="outlined"
        leftIcon={<Icon children={<PropertyEditIcon />} />}
      >
        {t("commEditing.editLayout")}
      </InnerGreyButton>
    </>
  );
}

const StyledSelect = styled(SelectBasic)({
  width: "max-content",
  marginLeft: "auto",
});

function PersonaSelector({
  disabled,
  communication,
  selectedDraft,
}: {
  disabled?: boolean;
  communication: Communication;
  selectedDraft: PersonaDraft;
}) {
  const t = useTranslate();
  const [personaId, setPersonaId] = useState(selectedDraft?.personaId);
  const [isGenerating, setIsGenerating] = useState(false);

  const { data: personas = [] } = usePersonaListQuery();
  const { mutate: changeDraftPersona } = useChangeDraftPersonaMutation({
    onStreamStart() {
      setIsGenerating(true);
    },
    onStreamEnd() {
      setIsGenerating(false);
    },
  });

  const draftId = selectedDraft?.id;
  const commId = communication.id;

  useEffect(() => {
    setPersonaId(selectedDraft?.personaId);
  }, [selectedDraft]);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const personaId = e.target.value;
      const currentPersonaId = selectedDraft?.personaId;
      setPersonaId(personaId);
      changeDraftPersona(
        { commId, draftId, personaId },
        {
          onError: (error) => {
            console.error("Error changing persona", error);
            setPersonaId(currentPersonaId);
          },
        },
      );
    },
    [setPersonaId, changeDraftPersona, commId, draftId, selectedDraft],
  );

  return (
    <Flex gap="sm-6" alignItems="center">
      <Typography variant="body/base/regular">
        {t("commEditing.targetPersona")}
      </Typography>
      <StyledSelect
        disabled={disabled || isGenerating}
        name="persona"
        value={personaId}
        onChange={handleChange}
      >
        {personas.map((persona) => (
          <SelectBasic.Option key={persona.id} value={persona.id}>
            {persona.name}
          </SelectBasic.Option>
        ))}
      </StyledSelect>
    </Flex>
  );
}
